import React from 'react';
import logo from './codecentric-600x290.png';
import FooterGenerator from './components/FooterGenerator'
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="headline">E-Mail Footer Generator</h1>
        <FooterGenerator/>
      </div>
    </div>
  );
}

export default App;
