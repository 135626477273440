import React, { Component } from 'react';
import Img from 'react-image';

class FooterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.parentData
    };
  }

  render() {
    return (
        <div className="footerContainer">
          <table id="zs-output-sig" border="0" cellpadding="0" cellspacing="0" style={{fontFamily:"Panton, Helvetica",lineHeight:"0px",fontSize:"1px",padding:"0px",borderSpacing:"0px",margin:"0px",borderCollapse:"collapse", width:"650px"}}>
            <tbody>
            <tr>
                <td>
                    <table border="0" cellpadding="0" cellspacing="0"
                           style={{fontFamily:"Panton, Helvetica,sans-serif",lineHeight:"0px",fontSize:"1px",padding:"0px",borderSpacing:"0px",margin:"0px",borderCollapse:"collapse"}}>
                        <tbody>
                        <tr>
                            <td width="7" style={{borderCollapse:"collapse",paddingRight: "7px",width: "7px"}}></td>
                            <td>
                                <table border="0" cellpadding="0" cellspacing="0"
                                       style={{fontFamily:"Panton, Helvetica,sans-serif",lineHeight:"0px",fontSize:"1px",padding:"0px",borderSpacing:"0px",margin:"0px",borderCollapse:"collapse"}}>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <table border="0" cellpadding="0" cellspacing="0"
                                                   style={{fontFamily:"Panton, Helvetica,sans-serif",lineHeight:"0px",fontSize:"1px",padding:"0px",borderSpacing:"0px",margin:"0px",borderCollapse:"collapse"}}>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <table border="0" cellpadding="0" cellspacing="0"
                                                               style={{fontFamily:"Panton, Helvetica,sans-serif",lineHeight:"0px",fontSize:"1px",padding:"0px",borderSpacing:"0px",margin:"0px",borderCollapse:"collapse"}}>
                                                            <tbody>
                                                            <tr>
                                                                <td style={{borderCollapse:"collapse",lineHeight:"32px",fontWeight:"normal"}}>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14px",fontStyle:"normal",lineHeight:"32px",fontWeight:"normal",color:`rgb(43,99,123)`,display:"inline"}}>{this.state.data.name}</span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14px",fontStyle:"normal",lineHeight:"32px",fontWeight:"normal",color:`rgb(79,94,99)`}}>&nbsp;|&nbsp;</span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14px",fontStyle:"normal",lineHeight:"32px",fontWeight:"normal",color:`rgb(79,94,99)`}}>{this.state.data.position}</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderCollapse:"collapse",fontFamily:"Panton,Helvetica,sans-serif",fontSize:"21.0px",fontStyle:"normal",lineHeight:"23px",fontWeight:"bold"}}>
                                                                    <img src="https://s3.eu-central-1.amazonaws.com/emailfooter.codecentric.nl/assets/7.png"/>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14px",fontStyle:"normal",lineHeight:"23px",fontWeight:"bold",color:`rgb(79,94,99)`, display:"inline"}}>codecentric Nederland B.V.</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderCollapse:"collapse",paddingBottom:"8px",height:"8px"}}></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderCollapse:"collapse",fontFamily:"Panton,Helvetica,sans-serif",fontSize:"15.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal"}}>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"16px",fontWeight:"normal",color:`rgb(59,131,170)`, display:"inline"}}>tel:&nbsp;</span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal",color:`rgb(59,131,170)`, display:"inline"}}>
                                                                        <a style={{textDecoration: "none",color:`rgb(59,131,170)`}} href="tel:+3185400011">+31 (0) 85 40000 11</a></span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"32px",fontWeight:"normal",color:`rgb(59,131,170)`}}>&nbsp;|&nbsp;</span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"16px",fontWeight:"normal",color:`rgb(59,131,170)`, display:"inline"}}>mob:&nbsp;</span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal",color:`rgb(59,131,170)`, display:"inline"}}>
                                                                        <a style={{textDecoration: "none",color:`rgb(59,131,170)`}} href={this.state.data.cellHref}>{this.state.data.cell}</a></span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"32px",fontWeight:"normal",color:`rgb(59,131,170)`}}>&nbsp;|&nbsp;</span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"16px",fontWeight:"normal",color:`rgb(59,131,170)`, display:"inline"}}>mail:&nbsp;</span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal",color:`rgb(59,131,170)`, display:"inline"}}>
                                                                        <a style={{textDecoration: "none",color:`rgb(59,131,170)`}} href={this.state.data.emailHref}>{this.state.data.email}</a></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderCollapse:"collapse",fontFamily:"Panton,Helvetica,sans-serif",fontSize:"15.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal"}}>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal",color:`rgb(59,131,170)`, display:"inline"}}>web:&nbsp;</span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal", display:"inline"}}><a style={{textDecoration: "none",color:`rgb(59,131,170)`}} href="https://www.codecentric.nl/" target="_blank">www.codecentric.nl</a></span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"32px",fontWeight:"normal",color:`rgb(59,131,170)`}}>&nbsp;|&nbsp;</span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal", display:"inline"}}><a style={{textDecoration: "none",color:`rgb(59,131,170)`}} href="https://www.werkenbijcodecentric.nl/" target="_blank">www.werkenbijcodecentric.nl</a></span>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"32px",fontWeight:"normal",color:`rgb(59,131,170)`}}>&nbsp;|&nbsp;</span>
                                                                    <span style={{fontFamily:"Panton, Helvetica," +
                                                                            " sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal", display:"inline"}}><a style={{textDecoration: "none",color:`rgb(59,131,170)`}} href="https://www.instana.nl/" target="_blank">www.instana.nl</a></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderCollapse:"collapse",fontFamily:"Panton, Helvetica, sans-serif",fontSize:"15.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal"}}>
                                                                    <span style={{fontFamily:"Panton, Helvetica, sans-serif",fontSize:"14.0px",fontStyle:"normal",color:`rgb(59,131,170)`,lineHeight:"17px",fontWeight:"normal", display:"inline"}}>
                                                                      Loevesteinstraat 20c&nbsp;|&nbsp;4834 ED Breda&nbsp;|&nbsp;Nederland&nbsp;&nbsp;
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderCollapse:"collapse",paddingBottom:"10px",height:"10px"}}></td>
                                                            </tr>
                                                            <tr>
                                                                <table border="0" cellpadding="0" cellspacing="0"
                                                                       style={{fontFamily:"Panton, Helvetica,sans-serif",lineHeight:"0px",fontSize:"1px",padding:"0px",borderSpacing:"0px",margin:"0px",borderCollapse:"collapse"}}>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td style={{paddingRight:"15px"}}>
                                                                            <img height="24" width="24" alt="linkedin"
                                                                                 border="0"
                                                                                 src="https://s3.eu-central-1.amazonaws.com/emailfooter.codecentric.nl/assets/1.png"/>
                                                                            <span style={{fontFamily:"Panton, Helvetica,sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal",color:`rgb(59,131,170)`, display:"inline",paddingLeft:"5px"}}>
                                                                                <a  style={{textDecoration: "none",color:`rgb(59,131,170)`}}
                                                                                    href="http://www.linkedin.com/company/codecentric-nederland-bv?trk=company_name"
                                                                                    target="_blank">Volg ons op LinkedIn</a></span>
                                                                        </td>
                                                                        <td style={{paddingRight:"15px"}}>
                                                                            <img height="24" width="24" alt="twitter"
                                                                                 border="0"
                                                                                 src="https://s3.eu-central-1.amazonaws.com/emailfooter.codecentric.nl/assets/2.png"/>
                                                                            <span style={{fontFamily:"Panton, Helvetica,sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal",color:`rgb(59,131,170)`, display:"inline",paddingLeft:"5px"}}>
                                                                                <a  style={{textDecoration: "none",color:`rgb(59,131,170)`}}
                                                                                    href="https://twitter.com/codecentric_nl"
                                                                                    target="_blank">Tweet met ons op Twitter</a></span>
                                                                        </td>
                                                                        <td>
                                                                            <img height="24" width="24" alt="linkedin"
                                                                                 border="0"
                                                                                 src="https://s3.eu-central-1.amazonaws.com/emailfooter.codecentric.nl/assets/3.png"/>
                                                                            <span style={{fontFamily:"Panton, Helvetica,sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal",color:`rgb(59,131,170)`, display:"inline",paddingLeft:"5px"}}>
                                                                                <a  style={{textDecoration: "none",color:`rgb(59,131,170)`}}
                                                                                    href="https://blog.codecentric.de/en/"
                                                                                    target="_blank">Lees mee op onze blog</a></span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{paddingRight:"15px"}}>
                                                                            <img height="24" width="24" alt="facebook"
                                                                                 border="0"
                                                                                 src="https://s3.eu-central-1.amazonaws.com/emailfooter.codecentric.nl/assets/4.png"/>
                                                                            <span style={{fontFamily:"Panton, Helvetica,sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal", display:"inline",paddingLeft:"5px"}}>
                                                                                <a  style={{textDecoration: "none",color:`rgb(59,131,170)`}}
                                                                                    href="https://www.facebook.com/codecentric.nl"
                                                                                    target="_blank">Like ons op Facebook</a></span>
                                                                        </td>
                                                                        <td style={{paddingRight:"15px"}}>
                                                                            <img height="24" width="24" alt="github"
                                                                                 border="0"
                                                                                 src="https://s3.eu-central-1.amazonaws.com/emailfooter.codecentric.nl/assets/5.png"/>
                                                                            <span style={{fontFamily:"Panton, Helvetica,sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal", display:"inline",paddingLeft:"5px"}}>
                                                                                <a  style={{textDecoration: "none",color:`rgb(59,131,170)`}}
                                                                                    href="https://github.com/organizations/codecentric"
                                                                                    target="_blank">Volg ons op Github</a></span>
                                                                        </td>
                                                                        <td>
                                                                            <img height="24" width="24" alt="CCNL"
                                                                                 border="0"
                                                                                 src="https://s3.eu-central-1.amazonaws.com/emailfooter.codecentric.nl/assets/6.png"/>
                                                                            <span style={{fontFamily:"Panton, Helvetica,sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal", display:"inline",paddingLeft:"5px"}}>
                                                                                <a  style={{textDecoration: "none",color:`rgb(59,131,170)`}}
                                                                                    href="https://www.codecentric.nl"
                                                                                    target="_blank">Check onze website</a></span>
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderCollapse:"collapse",paddingBottom:"10px",height:"10px"}}></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{borderCollapse:"collapse",fontFamily:"Panton,Helvetica,sans-serif",fontSize:"15.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal"}}>
                                                                    <span style={{fontFamily:"Panton, Helvetica," +
                                                                            " sans-serif",fontSize:"14.0px",fontStyle:"normal",lineHeight:"17px",fontWeight:"normal",color:`rgb(79,94,99)`, display:"inline"}}>codecentric Nederland BV is een Cloud Native Full Stack projecten en consultancy organisatie, met focus op&nbsp;<b style={{color:`rgb(79,94,99)`}}>Dev Ops</b>,&nbsp;<b style={{color:`rgb(79,94,99)`}}>Continuous Delivery</b>,&nbsp;<b style={{color:`rgb(79,94,99)`}}>Cloud</b> en&nbsp;<b style={{color:`rgb(79,94,99)`}}>Performance</b> . codecentric is kennis gedreven met ruim 500 medewerkers in Nederland, Duitsland, Bosnië en Herzegovina en Servië.</span>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            <tr>
                <td style={{borderCollapse:"collapse",paddingBottom:"8px",height:"8px"}}></td>
            </tr>
            </tbody>
          </table>
        </div>
    );
  }
}

export default FooterContainer;
