import React, { Component } from 'react';

class InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = props.parentData

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.handleCellChange = this.handleCellChange.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCellString = this.handleCellString.bind(this);

    this.parentCallback = props.parentCallback;
  }

  handleNameChange(event) {
    this.setState({name: event.target.value});
  }

  handlePositionChange(event) {
    this.setState({position: event.target.value});
  }

  handleCellChange(event) {
    this.setState({cell: event.target.value});
    this.setState({cellHref: this.handleCellString(event.target.value)});
  }

  handleMailChange(event) {
    this.setState({email: event.target.value});
    this.setState({emailHref: "mailto:" + event.target.value})
  }

  handleSubmit(event) {
    this.parentCallback(this.state)
    event.preventDefault();
  }

  handleCellString(cell) {
    if(cell.startsWith("0031")) {
      cell = cell.slice(4);
    }

    if(!cell.startsWith("+31")) {
      cell = "+31" + cell;
    }
    cell = cell.replace(/\s/g,'');
    cell = cell.replace('(0)','');
    cell = "tel:" + cell;

    return cell;
  }

  render() {
    return (
      <form className="input-form" onSubmit={this.handleSubmit}>
        <div className="form-element">
          <label>
            Name:&nbsp;
          </label>
          <input type="text" value={this.state.name} onChange={this.handleNameChange} />
        </div>
        <div className="form-element">
          <label>
            Position:&nbsp;
          </label>
          <input type="text" value={this.state.position} onChange={this.handlePositionChange} />
        </div>
        <div className="form-element">
          <label>
            Cellphone:&nbsp;
          </label>
          <input type="text" value={this.state.cell} onChange={this.handleCellChange} />
        </div>
        <div className="form-element">
          <label>
            E-Mail:&nbsp;
          </label>
          <input type="text" value={this.state.email} onChange={this.handleMailChange} />
        </div>
        <div className="form-element submit-button">
          <button onClick={this.handleSubmit} className="button"><span>Generate Footer</span></button>
        </div>
      </form>
    );
  }
}

export default InputForm;
