import React, { Component } from 'react';
import InputForm from './InputForm';
import FooterContainer from './FooterContainer';

class FooterGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: '',
        position: '',
        cell: '+31',
        cellHref: '',
        email: '@codecentric.nl',
        emailHref: ''
      },
      showFooter: false
    };
  }

  callbackFunction = (childData) => {
    this.setState({data: childData})
    this.setState({showFooter: true})
  }

  render() {
    return (
      <div className="wrapper">
        <InputForm parentCallback={this.callbackFunction} parentData={this.state.data} />
        <div className="instructions">
          <span>After generating your new E-Mail footer, copy it and paste it into your signature field in your <a className="cc-blue" href = "https://mail.google.com/mail/u/0/#settings/general">Gmail account settings.</a>.  This Generator is optimized for use with the Google Chrome browser. Using other browsers may lead to unexpected layout changes</span>
        </div>
        {this.state.showFooter && <FooterContainer parentData={this.state.data} />}
      </div>
    );
  }
}

export default FooterGenerator;
